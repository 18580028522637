export enum RuleId {
  DrawCharacterTile = 1,
  PlayTile,
  EndOfTurn,
  Cartographer,
  Navigator,
  Parrot,
  Cook,
  Gunner,
  Monkey,
  CarpenterEndOfGame,
  ParrotEndOfGame,
  SwabbyEndOfGame,
  LookoutEndOfGame,
  BoardEffect,
  BoardEffectCoinX,
  BoardEffectTreasureMap,
  BoardEffectFirstXThenY,
  BoardEndOfEffect,
  BoardEndOfGameCoinIfSame,
  BoardEndOfGameCoinIfAllDifferent,
  BoardEffectCoinPerDifferent,
  BoardEffectCoinPerFullColumn,
  BoardEffectFlip,
  BoardEffectReplay,
}
