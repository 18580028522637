import { Character } from './Character'

export const characterTiles = [
  [Character.Swabby, Character.Lookout],
  [Character.Swabby, Character.Parrot],
  [Character.Swabby, Character.Gunner],
  [Character.Swabby, Character.Monkey],
  [Character.Swabby, Character.Cook],
  [Character.Swabby, Character.Carpenter],
  [Character.Swabby, Character.Navigator],
  [Character.Swabby, Character.Cartographer],

  [Character.Cartographer, Character.Lookout],
  [Character.Cartographer, Character.Parrot],
  [Character.Cartographer, Character.Gunner],
  [Character.Cartographer, Character.Monkey],
  [Character.Cartographer, Character.Cook],
  [Character.Cartographer, Character.Carpenter],
  [Character.Cartographer, Character.Navigator],
  [Character.Cartographer, Character.Swabby],

  [Character.Navigator, Character.Lookout],
  [Character.Navigator, Character.Parrot],
  [Character.Navigator, Character.Gunner],
  [Character.Navigator, Character.Monkey],
  [Character.Navigator, Character.Cook],
  [Character.Navigator, Character.Carpenter],
  [Character.Navigator, Character.Swabby],
  [Character.Navigator, Character.Cartographer],

  [Character.Carpenter, Character.Lookout],
  [Character.Carpenter, Character.Parrot],
  [Character.Carpenter, Character.Gunner],
  [Character.Carpenter, Character.Monkey],
  [Character.Carpenter, Character.Cook],
  [Character.Carpenter, Character.Swabby],
  [Character.Carpenter, Character.Navigator],
  [Character.Carpenter, Character.Cartographer],

  [Character.Cook, Character.Lookout],
  [Character.Cook, Character.Parrot],
  [Character.Cook, Character.Gunner],
  [Character.Cook, Character.Monkey],
  [Character.Cook, Character.Swabby],
  [Character.Cook, Character.Carpenter],
  [Character.Cook, Character.Navigator],
  [Character.Cook, Character.Cartographer],

  [Character.Monkey, Character.Lookout],
  [Character.Monkey, Character.Parrot],
  [Character.Monkey, Character.Gunner],
  [Character.Monkey, Character.Swabby],
  [Character.Monkey, Character.Cook],
  [Character.Monkey, Character.Carpenter],
  [Character.Monkey, Character.Navigator],
  [Character.Monkey, Character.Cartographer],

  [Character.Gunner, Character.Lookout],
  [Character.Gunner, Character.Parrot],
  [Character.Gunner, Character.Monkey],
  [Character.Gunner, Character.Cook],
  [Character.Gunner, Character.Carpenter],
  [Character.Gunner, Character.Navigator],
  [Character.Gunner, Character.Cartographer],
  [Character.Gunner, Character.Swabby],

  [Character.Parrot, Character.Lookout],
  [Character.Parrot, Character.Cartographer],
  [Character.Parrot, Character.Gunner],
  [Character.Parrot, Character.Monkey],
  [Character.Parrot, Character.Cook],
  [Character.Parrot, Character.Carpenter],
  [Character.Parrot, Character.Navigator],
  [Character.Parrot, Character.Swabby],

  [Character.Lookout, Character.Swabby],
  [Character.Lookout, Character.Parrot],
  [Character.Lookout, Character.Gunner],
  [Character.Lookout, Character.Monkey],
  [Character.Lookout, Character.Cook],
  [Character.Lookout, Character.Carpenter],
  [Character.Lookout, Character.Navigator],
  [Character.Lookout, Character.Cartographer],
]