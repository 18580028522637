export enum Character {
  Swabby = 1,
  Cartographer,
  Navigator,
  Carpenter,
  Cook,
  Monkey,
  Gunner,
  Parrot,
  Lookout
}